var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('AreasModal',{attrs:{"modal":_vm.modal,"areaId":_vm.areaId,"AreaItems":_vm.AreaItems,"edit":_vm.edit,"title":_vm.titleModal},on:{"update:modal":function($event){_vm.modal=$event},"submited":_vm.handleSubmit,"close":_vm.closeModal}}),_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: ('AREA'),
          placement: 'top-end'
        }),expression:"{\n          content: ('AREA'),\n          placement: 'top-end'\n        }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.toggleAdd}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedItems,"fields":_vm.fields,"column-filter":"","noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":_vm.tableText.itemsPerPage,"hover":"","small":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"SquareMeters",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[_vm._v(" "+_vm._s(item.SquareMeters ? _vm.formatMilDecimal(item.SquareMeters?.toFixed(2)) : '0,00')+" ")])]}},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.edit')+' '+('AREA'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.edit')+' '+('AREA'),\n              placement: 'top-end'\n            }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }